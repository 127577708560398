import {useState} from "react"
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";


import slide1 from "../../assets/images/image2.png";
import slide2 from "../../assets/images/image6.png";
import slide3 from "../../assets/images/image9.png";
import Carousel from "react-bootstrap/Carousel";
import "../../sass/mobile/header.scss";

// import Banner from "./banner";

function MobileHeader() {
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
  return (
    <div className=" main-mobile">
      <div className="container">
        <div className="hero-main">
          <div className="image-section">
            <Carousel fade indicators={false}>
              <Carousel.Item interval={3000}>
                <img src={slide1} alt="" />
                <Carousel.Caption>
                  <h2>
                    Welcome to RockBank - the Family that cares about you!
                  </h2>
                  <p>
                    With RockBank, We are rethinking the norm in the financial
                    industry to provide more value to clients that could
                    transcend their finances beyond their lifetime.
                  </p>
                  <div className="btn-mobile">
                    <Link
                      type="button"
                      to="/"
                      state={"about"}
                      onClick={handleShow}
                    >
                      Get Started
                    </Link>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img src={slide2} alt="" />
                <Carousel.Caption>
                  <h2>
                    Welcome to RockBank - the Family that cares about you!.
                  </h2>
                  <p>
                    With RockBank, We are rethinking the norm in the financial
                    industry to provide more value to clients that could
                    transcend their finances beyond their lifetime.
                  </p>
                  <div className="btn-mobile">
                    <Link
                      type="button"
                      to="/"
                      state={"about"}
                      onClick={handleShow}
                    >
                      Get Started
                    </Link>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img src={slide3} alt="" />
                <Carousel.Caption>
                  <h2>
                    Welcome to RockBank - the Family that cares about you!.
                  </h2>
                  <p>
                    With RockBank, We are rethinking the norm in the financial
                    industry to provide more value to clients that could
                    transcend their finances beyond their lifetime.
                  </p>
                  <div className="btn-mobile">
                    <Link
                      type="button"
                      to="/"
                      state={"about"}
                      onClick={handleShow}
                    >
                      Get Started
                    </Link>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Plan </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-btn" style={{ display: "block" }}>
            <div className="btn" style={{ background: "#00009f", margin: 10 }}>
              <Link
                type="button"
                to="https://forms.gle/13BkGZco1tdGNVqg8"
                target="_blank"
                style={{ color: "#ffffff", fontSize: 14 }}
              >
                Individual Plan
              </Link>
            </div>
            <div className="btn" style={{ background: "#00009f", margin: 10 }}>
              <Link
                type="button"
                to="/retail"
                style={{ color: "#ffffff", fontSize: 14 }}
              >
                Business Plan
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MobileHeader;
