import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import slide1 from "../assets/images/image2.png";
import slide2 from "../assets/images/image6.png";
import slide3 from "../assets/images/image9.png";

import Carousel from "react-bootstrap/Carousel";
import "../sass/herosection.scss";

function HeroSection() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className=" main-desktop">
      <div className="container">
        <div className="hero-main">
          <div className="text-section">
            <h2>Welcome to RockBank - The family that cares about you!</h2>

            <p>
              RockBank is your trusted partner in financial services, savings,
              and investment management in Nigeria. At RockBank, we are
              committed to providing innovative financial solutions tailored to
              meet the diverse needs of our clients. With a strong focus on
              customer satisfaction and financial empowerment, we strive to be
              the leading financial institution in Nigeria.
            </p>
            <p>
              With a strong focus on customer satisfaction and financial
              empowerment, we strive to be the leading financial institution in
              Nigeria.
            </p>

            <p>
              At RockBank, we are rethinking the norms in the financial industry
              to provide more value to clients, transcending their finances
              beyond their lifetime.
            </p>
            <p></p>
            <div className="btn-max">
              <Link type="button" to="/" state={"about"} onClick={handleShow}>
                Get Started
              </Link>
            </div>
          </div>
          <div className="image-section">
            <Carousel fade indicators={false}>
              <Carousel.Item interval={3000}>
                <img src={slide1} alt="" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img src={slide2} alt="" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img src={slide3} alt="" />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Plan </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="modal-btn"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <div className="btn" style={{ background: "#00009f", width: 250 }}>
              <Link
                type="button"
                to="https://forms.gle/13BkGZco1tdGNVqg8"
                target="_blank"
                style={{ color: "#ffffff", fontSize: 22 }}
              >
                Individual Plan
              </Link>
            </div>
            <div className="btn" style={{ background: "#00009f", width: 250 }}>
              <Link
                type="button"
                to="/retail"
                style={{ color: "#ffffff", fontSize: 22 }}
              >
                Business Plan
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HeroSection;
