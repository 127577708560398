// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

// Create Context
export const AuthContext = createContext();

// Provider Component
export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState({
    token: localStorage.getItem("token"),
    user: null,
    isAuthenticated: false,
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  useEffect(() => {
    if (authData.token) {
      const decoded = jwtDecode(authData.token);
      setAuthData((prevState) => ({
        ...prevState,
        user: decoded.user,
        isAuthenticated: true,
      }));
    }
  }, [authData.token]);

  const signup = async (formData) => {
    try {
      const res = await axios.post(
        "https://rockbank-server.vercel.app/register",
        formData
      );

      setEmail(formData.email);
      setPhoneNumber(formData.phoneNumber);

      console.log("res:", res.data.user);
      localStorage.setItem("token", res.data.token);
      setAuthData({
        token: res.data.token,
        user: res.data.user,
        isAuthenticated: true,
      });
    } catch (err) {
      console.log(err);
      // throw err.response.data.msg || "Signup failed";
    }
  };

  const login = async (formData) => {
    try {
      const res = await axios.post(
        "https://rockbank-server.vercel.app/login",
        formData
      );

      //console.log(res);

      localStorage.setItem("token", res.data.token);
      setAuthData({
        token: res.data.token,
        user: res.data.user,
        isAuthenticated: true,
      });
    } catch (err) {
      console.log("err:", err)
      //throw err.response.data.msg || "Login failed";
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setAuthData({
      token: null,
      user: null,
      isAuthenticated: false,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        authData,
        signup,
        login,
        logout,
        email,
        phoneNumber,
        trackingId,
        setTrackingId,
        accountNumber,
        setAccountNumber,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
