import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../sass/login.scss";
import { AuthContext } from "../context/AuthContext";

import { RotatingLines } from "react-loader-spinner";

function Login() {
  const {authData, login } = useContext(AuthContext);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState([]);

  const [pageLoading, setPageLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPageLoading(true)
    //console.log(authData.data)
    try {
      await login(form);
      //console.log("Result:", authData.user); 
      navigate("/investor");
      // Redirect or update UI as needed
      // if (authData.user.role === "Investor") navigate("/investor");
      // if (authData.user.role === "FinancialStaff") navigate("/financial-planner");
      // if (authData.user.role === "Admin") navigate("/admin");
    } catch (error) {
      
      console.log(error)
    }finally{
      setPageLoading(false)
    }
  };

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="login-container">
      <div className="register-content">
        <div className="reg-form-section">
          <p style={{ color: "red" }}>{error.message}</p>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="email"
                  aria-describedby="emailHelp"
                  value={form.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="forgetPassword">
                <Link to="/">Forget Password?</Link>
              </div>

              <div className="btn-section">
                <button type="submit" className="btn-submit">
                  {pageLoading ? (
                    <RotatingLines
                      visible={true}
                      height="25"
                      width="25"
                      color="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    <span>Login</span>
                  )}
                </button>
              </div>
              <div className="signup">
                <span>Dont have account?</span>
                <Link to="/register">Register</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
